import { Component, EventEmitter, Output } from '@angular/core';
import { EnvironmentActions } from '@app/core/actions';
import * as fromRoot from '@app/reducers/index';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-iecook',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent {

  cookieDisplayed = true;
  @Output() manageCookies: EventEmitter<boolean> = new EventEmitter();
  hostname = environment.appDomainWithProtocol;

  constructor(
    private readonly store: Store<fromRoot.State>,
  ) { }


  hideMainCookieTemplate(): void {
    this.cookieDisplayed = false;
    this.manageCookies.emit(true);
  }

  acceptCookies() {
    this.hideMainCookieTemplate();
    this.store.dispatch(EnvironmentActions.AcceptAllCookies());
    this.store.dispatch(EnvironmentActions.CloseCookiesConsentConfiguration());
  }

  denyAllCookies() {
    this.hideMainCookieTemplate();
    this.store.dispatch(EnvironmentActions.DenyAllCookies());
    this.store.dispatch(EnvironmentActions.CloseCookiesConsentConfiguration());
  }
}
