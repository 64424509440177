<div class="iekooc-main-container" *ngIf="cookieDisplayed">
    <div class="iekooc-container" >
        <div class="iekooc-content">
            <span class="title">{{ 'COOKIES_OVERLAY_TITLE' | translate }}</span>
            <p class="description">
                {{ 'COOKIES_OVERLAY_DESCRIPTION_TEXT_1' | translate }}
                <a target="_blank" href="{{ hostname }}{{ 'COOKIES_OVERLAY_DESCRIPTION_LINK_URL_1' | translate }}" translate>COOKIES_OVERLAY_DESCRIPTION_LINK_LABEL_1</a>
            </p>
            <div class="cta-container">
                <button class="btn-v3 red cta-link" (click)='acceptCookies()'>{{ 'COOKIES_OVERLAY_BUTTON_ACCEPT' | translate }}</button>
                <button class="btn-v3 dark-filaire" (click)='denyAllCookies()'>{{ 'COOKIES_OVERLAY_BUTTON_DENY' | translate }}</button>
            </div>
        </div>
    </div>
</div>