import { isPlatformServer } from '@angular/common';
import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { EnvironmentActions } from '@app/core/actions';
import * as fromRoot from '@app/reducers/index';
import { hostnameIsFromMainDomain } from '@app/shared/helpers/location';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { REQUEST } from 'src/express.tokens';
import { Request } from 'express';

@Component({
  selector: 'app-cgu',
  templateUrl: './cgu.component.html',
  styleUrls: ['./cgu.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CguComponent implements OnInit, AfterContentChecked, OnDestroy {

  template: 'MAIN' | 'VIEWER';
  isMainDomain: boolean;
  subscriptions: Subscription[] = [];
  adaptiveMessage: { supportedCountry: boolean, value: string | null, country?: string };
  hostname = environment.appDomainWithProtocol;
  constructor(
    private readonly store$: Store<fromRoot.State>,
    public router: Router,
    @Optional() @Inject(REQUEST) private req: Request,
    @Inject(PLATFORM_ID) private platformId: any,
    private cd: ChangeDetectorRef,
    private translateService: TranslateService) {

    this.isMainDomain = isPlatformServer(this.platformId) ? hostnameIsFromMainDomain(`${this.req.headers['x-forwarded-host']}`) : hostnameIsFromMainDomain(location.hostname);
    this.subscriptions.push(
      this.router.events.subscribe((data) => {
        if (!this.template && data instanceof ActivationEnd) {
          const routeData = data?.snapshot.data;
          routeData.moduleName === 'ViewerModule' ? this.template = 'VIEWER' : this.template = 'MAIN';
        }
      })
    );
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  acceptCGU(): void {
    this.store$.dispatch(EnvironmentActions.AcceptCGU());
  }

  ngAfterContentChecked() {
    this.cd.markForCheck();
  }

  get linkAboutTemplate() {
    return `<a id="cgu-banner-about" href="${this.translateService.instant('CGU_MOBILE_LEARN_ABOUT_SMASH_URL_LINK_ABOUT')}" target="_blank">${this.translateService.instant('CGU_MOBILE_LEARN_ABOUT_SMASH_LABEL_LINK_ABOUT')}</a>`;
  }

  get linkAPITemplate() {
    return `<u><a id="cgu-banner-api" href="${this.translateService.instant('CGU_MOBILE_LEARN_ABOUT_SMASH_URL_LINK_API')}" target="_blank">${this.translateService.instant('CGU_MOBILE_LEARN_ABOUT_SMASH_LABEL_LINK_API')}</a></u>`;
  }

  get viewLoading() {
    return !!isPlatformServer(this.platformId)
  }
}
